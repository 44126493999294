import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import gologo from '../../images/portfolio/goapps/go-apps.svg';


const GoApps = () => {
	const data = useStaticQuery(graphql`
		query {
			img1: file(relativePath: { eq: "portfolio/goapps/01.png" }) {
				childImageSharp {
					fluid(maxWidth: 700) {
						...GatsbyImageSharpFluid_noBase64
					}
				}
			}
			img2: file(relativePath: { eq: "portfolio/goapps/02.png" }) {
				childImageSharp {
					fluid(maxWidth: 700, quality: 100) {
						...GatsbyImageSharpFluid_noBase64
					}
				}
			},
			img3: file(relativePath: { eq: "portfolio/goapps/03.png" }) {
				childImageSharp {
					fluid(maxWidth: 700, quality: 100) {
						...GatsbyImageSharpFluid_noBase64
					}
				}
			}
		}
	`)

	return(
		<Layout>
			<SEO title="Case Studies: GoApps" />
			<div className="case-study">
				<div className="intro">
					<div className="brand">
						<img className='mx-auto' width="75" src={gologo} alt='' />
					</div>
					<h1 className='page-title display'>_Go Apps</h1>
					<div className="base-img">
						<Img fluid={data.img1.childImageSharp.fluid} />
					</div>
				</div>

				<div className="row text-center">
					<div className="col-md-7 mx-auto">
						<p className='text-primary-dark mb-4'>UK based education specialists Squirrel Learning partnered with Axe Edge to develop a suite of applications centered around improving reading and writing skills for primary schools.</p>
						<div className="mb-4">
							<a className='btn mr-2' href="https://go-write.co.uk">go-write.co.uk</a>
							<a className='btn mr-2' href="https://go-read.co.uk">go-read.co.uk</a>
						</div>
						<ul>
							<li className='label label-primary mr-1'>Web Design</li>
							<li className='label label-primary mr-1'>UX Design</li>
							<li className='label label-primary mr-1'>Branding</li>
							<li className='label label-primary mr-1'>Full-Stack Development</li>
							<li className='label label-primary'>e-commerce</li>
						</ul>
					</div>
				</div>

				<div className="content-img">
					<Img fluid={data.img2.childImageSharp.fluid} />
				</div>
				
				<div className="content-img content-img-end">
					<Img fluid={data.img3.childImageSharp.fluid} />
				</div>
			</div>		
		</Layout>
	)
}



export default GoApps
